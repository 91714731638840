import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h3 style={{ fontWeight: 'normal' }}>
      Happy anniversary my angel!
      These last
      {' '}
      <span style={{ fontWeight: 'bold' }}>7 years </span>
      {' '}
      with you have meant so much to me, and been so many things to us.
    </h3>
    <h3>
      2019
      {' '}
      <span style={{ fontWeight: 'normal' }}>Huge, Change, Together</span>
    </h3>
    <h3>
      2018
      {' '}
      <span style={{ fontWeight: 'normal' }}>Big, Special, Fun</span>
    </h3>
    <h3>
      2017
      {' '}
      <span style={{ fontWeight: 'normal' }}>Lovely, Exciting, Grateful</span>
    </h3>
    <h3>
      2016
      {' '}
      <span style={{ fontWeight: 'normal' }}>Tough, Strong, Special</span>
    </h3>
    <h3>
      2015
      {' '}
      <span style={{ fontWeight: 'normal' }}>Young, Growing, Busy</span>
    </h3>
    <h3>
      2014
      {' '}
      <span style={{ fontWeight: 'normal' }}>Abundant, Joyous, Spectacular</span>
    </h3>
    <h3>
      2013
      {' '}
      <span style={{ fontWeight: 'normal' }}>Exciting, Memorable, Surprising</span>
    </h3>
    <h3 style={{ fontWeight: 'normal' }}>
      Most of all they have been the best years of my life,
      and I am so excited and lucky to be spending the rest of mine with you.
      <br />
      <br />
      <span style={{ fontWeight: 'bold' }}>
        You are the greatest person I know.
        The funniest and most fun friend, and the sexiest wife in the whole world.
      </span>
      <br />
      <br />
      I know we can&apos;t get anything delivered during lockdown, but I have gone
      ahead and bought some things I thought might make you feel as special as you are to me.
      If you wanna know what they are you can ask, otherwise they can stay a surprise
      until delivery after lockdown.
    </h3>
  </Layout>
);

export default IndexPage;
